import React,  { useRef, useState, useEffect } from "react";
import { useTimer } from 'react-timer-hook';
import { useForm, SubmitHandler } from "react-hook-form"
import { Row, Col } from "react-bootstrap";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { MuiTelInput } from 'mui-tel-input'
import { Carousel } from 'react-responsive-carousel';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';

import './css/carousel.css'
import './css/style.css'

function App() {
  const config = { 
    home : 'https://portal.netmaster.id/',
    //home : 'https://localhost:3000/',
    hostApi : 'https://api-portal.netmaster.id/'
  }
  const { register, setValue, getValues, formState: { errors }, handleSubmit } = useForm()
  
  
  var url_string = window.location.href;
  var url = new URL(url_string);
  var s = url.searchParams.get("s");
  var h = url.searchParams.get("h");

  const [secret,setSecret] = useState(s)
  const [host,setHost] = useState(h)
  const [_404,set404] = useState(1)
  const [form,setForm] = useState(1)
  const [timer,setTimer] = useState(6)
  const [tel, setTel] = React.useState('+62')
  const [otp, setOtp] = React.useState('')
  const [comp, setComp] = useState([])
  const [error,setError] = React.useState([])
  const [loading,setLoading] = React.useState(false)
  const [stGetdata,setStGetdata] = useState(true)

  const time = new Date();
  time.setSeconds(time.getSeconds() + timer); 

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ time, onExpire: () => console.warn('onExpire called') });

  const getData = (data) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch(config.hostApi + 'setting', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setComp(result.data)
            setStGetdata(false)
            setLoading(false)
            set404(2)
          }else{
            setError(result.errors)
            setLoading(false)
            set404(0)
            
          }
        })
        .catch((error)=>{
          setLoading(false)
          console.log(error)
          set404(0)
        })
        
  }


  const authCode = (data) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const formData = new URLSearchParams(new FormData());
    let telp = tel.replace(/ |\+/gi,'')
    formData.append("otp", otp)
    formData.append("wa_number", telp)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };
    
    fetch(config.hostApi + 'authenticate', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setLoading(false)
            console.log('onConnect')
            window.location.href= 'http://' + h + '/login.html?s=' + secret;
          }else{
            setError(result.message)
            setLoading(false)
          }
        })
        .then(()=>{
          setLoading(false)
        })
        
  }

  const onSubmit = (data) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const formData = new URLSearchParams(new FormData());
    let telp = tel.replace(/ |\+/gi,'')
    formData.append("name", getValues('name'))
    formData.append("wa_number", telp)
    formData.append("data", '[{"email":"'+ getValues('email')+'"}]')

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    setLoading(false)
    setForm(2)
    fetch(config.hostApi + 'signup', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setLoading(false)
            setForm(2)
            
            const time = new Date();
            time.setSeconds(time.getSeconds() + timer);
            restart(time)
          }else{
            setError(result.errors)
            setLoading(false)
          }
        })
        .then(()=>{
          setLoading(false)
        })
        
  }

  
  const resend = () =>{
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const formData = new URLSearchParams(new FormData());
    let telp = tel.replace(/ |\+/gi,'')
    formData.append("name", getValues('name'))
    formData.append("wa_number", telp)
    formData.append("data", '[{"email":"'+ getValues('email')+'"}]')

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    fetch(config.hostApi + 'signup', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setLoading(false)
            
            setForm(2)
            const time = new Date();
            time.setSeconds(time.getSeconds() + timer);
            restart(time)
          }else{
            setError(result.errors)
            setLoading(false)
          }
        })
        .then(()=>{
          setLoading(false)
        })
  }
  const otpChange = (newValue) => {
    setOtp(newValue)
  }
  const telChange = (newValue) => {
    setTel(newValue)
  }

  useEffect(() => {
    getData()
  },[])

  if(_404 === 0){
    return(<h1>Error 404</h1>)
  }else if(_404 === 1){
    return(<h1>Loading</h1>)
  }else{
    return (
      <Row>
        <Col md={12}>
					<div className="wrap d-md-flex">
						<div className="img">
                    <Carousel showIndicators={false} showThumbs={false} autoPlay={true} infiniteLoop={true}>
                          {comp.photos? (comp.photos.map((photo,key) => (
                              <div><img src={photo} /></div>
                          ))):('')}
                    </Carousel>
			      </div>
            {form==1?(
                <div className="login-wrap p-4 p-md-5">
                    <div className="d-flex">
                        <div className="w-100">
                          <h3 className="mb-4">Please fill in the form below to connect {comp.company_name} Wifi.</h3>
                        </div>		
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="signin-form">
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="name">Your Name</label>
                          <input type="text" className="form-control" placeholder="Your Name" required 
                          aria-invalid={errors.name ? "true" : "false"}
                          {...register("name", { required: true, maxLength: 30 })} />
                          {errors.name && <span className="error">{errors.name.message}</span>}
                          {error.filter(error => error.path === 'name').length > 0 && <span className="error">{error.filter(error => error.path === 'name')[0].msg}</span>}
                        </div>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="password">Whatsapp Number</label>
                            <MuiTelInput value={tel} className="form-control" onChange={telChange} />
                            {error.filter(error => error.path === 'wa_number').length > 0 && <span className="error">{error.filter(error => error.path === 'wa_number')[0].msg}</span>}
                        </div>
                        <div className="form-group mb-3">
                          <label className="label" htmlFor="password">Email Address</label>
                          <input
                                id="email"
                                className="form-control" 
                                placeholder="Email"
                                {...register("email", {
                                  required: "required",
                                  pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Entered value does not match email format",
                                  },
                                })}
                                type="email" 
                                required
                              />
                              {errors.email && <span role="alert" style={{color:'red', margin:'3px', fontSize:'14px'}}>{errors.email.message}</span>}
                              {/*error.filter(error => error.path === 'email').length > 0 && <span className="error">{error.filter(error => error.path === 'email')[0].msg}</span>*/}
                        </div>
                        <div className="form-group">
                              <MuiJoy.Button
                                type="submit"
                                variant="solid" 
                                className="form-control btn btn-primary rounded submit px-3"
                                loading={loading} 
                                startDecorator={<MuiIcon.TapAndPlay />}
                                loadingPosition="start"
                                > CONNECT
                              </MuiJoy.Button>
                        </div>
                      
                    </form>
                </div>
            ):('')}
            {form==2?(
                <div className="login-wrap p-4 p-md-5">
                    <div className="d-flex">
                        <div className="w-100">
                          <h3>Whatsapp verification.</h3>
                          <h5 className="mb-4">Enter the code we just sent on your phone.</h5>
                        </div>		
                    </div>
                    <form onSubmit={handleSubmit(authCode)} className="signin-form">
                      <div className="form-group d-flex flex-row mt-2">
                          <MuiOtpInput id="otp" value={otp} onChange={otpChange} enableFocus autoFocus />
                      </div>
                      <div className="form-group text-center mt-5">
                            {seconds > 0 ? (<p className="label">Time Left : {seconds}</p>):('')}
                            {seconds == 0 ? (
                              <p className="d-block" style={{color:'#111111'}}>Didn't receive the code? &nbsp; &nbsp; 
                              <MuiJoy.Button
                                type="button"
                                variant="solid" 
                                className="btn btn-primary rounded px-3"
                                loading={loading} 
                                startDecorator={<MuiIcon.Sync />}
                                loadingPosition="start"
                                onClick={()=>{
                                    const time = new Date();
                                    time.setSeconds(time.getSeconds() + timer);
                                    resend()
                                    restart(time)
                                }}
                                > RESEND
                              </MuiJoy.Button>
                            </p>):('')}
                            <h6 style={{color:'red', textAlign:'center'}}>{error}</h6>
                      </div>
                      <div className="form-group mt-5">
                            <MuiJoy.Button
                                type="submit"
                                variant="solid" 
                                className="form-control btn btn-primary rounded submit px-3"
                                loading={loading} 
                                startDecorator={<MuiIcon.LockOpen />}
                                loadingPosition="start"
                                > SUBMIT
                              </MuiJoy.Button>
                        </div>
                    </form>
                </div>
            ):('')}
		      </div>
				</Col>
      </Row>
    );
  }
  
}

export default App;
